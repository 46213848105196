/**
 * Created by ivan on 09.09.2016.
 */
class SEO{
    constructor(){
        this._events();
        this.focus;

    }
    
    _events(){
        var self = this;

        $(document).ready(function(){
            self.focus = $('#type_url');
        });

        $(document).on('blur','input.input-sm',function(){
            self.focus = $(this);
        }).on('click','.clear-input',function(e){
            e.preventDefault();
            console.log($(this).closest('.row').find('.input-sm').val(''));
        }).on('click','.entity a.glyphicon',function(){
            var insert = $(this).attr('insert');
            self.focus.val(self.focus.val() + insert);
            return false
        });
    }
}