/**
 * Created by ivan on 09.09.2016.
 */
class Products{
    constructor(){
        this._events();
        this.$option = $('#edit_products_value');
        this.loadOption();
    }

    loadOption(){
        if( !this.$option.length ) return;


        $.ajax({
            url: '/admin/product/ajax/options-product/?product_id=' + this.$option.attr('data-item-id') + '&category_id=' + this.$option.attr('data-category-id'),
            type: 'GET',
            dataType: 'JSON',
            success: (r) => {
                this.$option.html(r['html']);
            },
            error: ( error ) => {
                console.log(error);
            }
        });

    }

    _events(){
        $(document.body).on('chang','#category_id_edit_product',function (e) {
            console.log(1);
        });
    }
}