/**
 * Created by ivan on 09.09.2016.
 */
class CategorySubMenu{
    constructor(){
        this._events();
    }
    
    _events(){
        $(document).ready(function() {
            var last_valid_selection = null;
            $('#userRequest_activity').change(function(event) {
                if ($(this).val().length > 6) {
                    $(this).val(last_valid_selection);
                } else {
                    last_valid_selection = $(this).val();
                }
            });
        });
    }
}