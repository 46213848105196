/**
 * Created by ivan on 25.08.2016.
 */
class AdminOrder{
    constructor(){
        this._events();
        this.initAutocomplete();
    }

    initAddNewItem(){
        alert(1);
    }

    initAutocomplete(){
        var template = _.template($('#edit_item_in_order').html());

        $('.js-search-input-ui').each(function () {
            $( this ).autocomplete({
                source: function( request, response ) {
                    $.ajax({
                        url: '/search/product/name-or-articul/' + this.element[0].value ,
                        type: 'GET',
                        dataType: 'json',
                        success: function (data) {
                            let d = [];
                            _.forEach(data , function ( item , key ) {
                                d.push({
                                    id    : item['id'],
                                    value : item['name'] + ' ' + item['artikul'],
                                    count : 1,
                                    cost  : item['price']
                                });
                            });
                            response( d );
                        },
                        error: function (data) {
                        }
                    });
                },
                select: function( event, ui ) {
                    let $this = $(this);
                    let parent = $this.parent();
                    ui.item.discount = parent.find('input[name="discount"]').val();
                    ui.item.summ = ui.item.cost * ui.item.count;
                    ui.item.discount_summ = ui.item.summ + ui.item.summ * ui.item.discount;
                    parent.find('.lodash_render_item_edit').html( template(ui.item) );

                    $(this).attr('name','Item[' + ui.item.id + '][name]');
                }
            });
        });
    }

    _events(){

    }
}