/**
 * Created by ivan on 25.08.2016.
 */
class AdminApp{
    constructor(){
        this.order    = new AdminOrder();
        this.products = new Products();
        this.filter = new Filter();
        this.category_sub_menu = new CategorySubMenu();
        this.SEO = new SEO();
    }
}