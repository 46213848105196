/**
 * Created by ivan on 09.09.2016.
 */
class Filter{
    constructor(){
        this._events();
    }
    
    _events(){
        $(document).ready(function(){
            $("[type='checkbox']:not(#photo_export)").bootstrapSwitch();
        });

        $(document.body).on('switchChange.bootstrapSwitch',"[type='checkbox']",function(event, state) {
            var value = $(this).closest('.row').children('[val]').attr('val');
            console.log(value);
            console.log(state); // true | fal

            $.ajax({
                url: '/admin/filter/change',
                type: 'GET',
                data: {value: value, state: state ? 1 : 0}
            });
        });
    }
}